import { defaultTheme } from "@ioxio-priv/dataspace-ui"
import { getRuntimeSettings } from "@ioxio-priv/dataspace-utils"

const runtimeSettings = getRuntimeSettings()
const ENV = runtimeSettings.ENV || "development"
const dataspaceDomain = runtimeSettings.DATASPACE_BASE_DOMAIN
export function isEnabledFromEnv(name) {
  const value = import.meta.env[`VITE_FEATURE_${name.toUpperCase()}`]
  if (value) {
    return value === "1"
  }
  return undefined
}

// Dataspace specific config
export const baseDsConfig = {
  baseUrl: runtimeSettings.DEVELOPER_PORTAL_URL,
  components: {
    // Some dataspaces can not have data definitions viewer
    "Data Definitions": runtimeSettings.DEFINITIONS_VIEWER_URL,
    "Developer Portal": runtimeSettings.DEVELOPER_PORTAL_URL,
  },
  navFooterLinks: {
    Privacy: `https://${dataspaceDomain}/privacy`,
    Legal: `https://${dataspaceDomain}/terms-of-service`,
  },
  dataspaceVariant: "IOXIO Dataspace Sandbox",
  dataspaceHome: `https://${dataspaceDomain}`,
  footerExternalLinks: [
    { name: "IOXIO", url: "https://ioxio.com" },
    { name: "IOXIO Dataspace Sandbox", url: "https://sandbox.ioxio-dataspace.com" },
    { name: "GitHub", url: "https://github.com/ioxio-dataspace" },
  ],
  whitelabel: false,
}

// Developer Portal specific config
export const baseConfig = {
  features: {
    groupInvitations: ["development", "test"].includes(ENV),
  },
  sourcesRepo: "https://github.com/ioxio-dataspace/sandbox-definitions",
  definitions:
    "https://github.com/ioxio-dataspace/sandbox-definitions/tree/main/DataProducts",
  termsOfService: "https://sandbox.ioxio-dataspace.com/terms-of-service",
  termsOfServiceName: "Terms of Service",
  inviteOnly: runtimeSettings.INVITE_ONLY,
  requireVerifiedApiTokens: runtimeSettings.REQUIRE_VERIFIED_API_TOKENS,
  apiTokensRequirePayment: runtimeSettings.API_TOKENS_REQUIRE_PAYMENT,
  moderatedGroupCreation: runtimeSettings.MODERATED_GROUP_CREATION,
  supportEmail: "support@ioxio.com",
  tokenVerificationCode:
    "https://github.com/ioxio-dataspace/example-productizer/blob/3dd2435183ed5cbbd30c99a473a8d9c2ccf6b7c7/app/api_tokens.py#L221-L278",
  privacyPolicy: "https://sandbox.ioxio-dataspace.com/privacy/",
  productGateway: runtimeSettings.PRODUCT_GATEWAY_URL,
  dataspaceDomain: dataspaceDomain,
  dataspaceWebsite: `https://${dataspaceDomain}`,
  jwksUrl: `https://${dataspaceDomain}/.well-known/jwks.json`,
  verifyingAPITokensGuide:
    "https://ioxio.notion.site/Verifying-API-tokens-1528e3ae865b804c9f2dd354b143c743",
  productizationGuide: "https://miro.com/app/board/o9J_lbLav_s=/",
  exampleProductizer: "https://github.com/ioxio-dataspace/example-productizer",
  exampleApplication: "https://github.com/ioxio-dataspace/example-app",
  partyConfigurationDocs: "https://docs.ioxio.dev/schemas/party-configuration/",
  disableApps: false,
  externalAppManagement: false,
  externalAppSettings: {},
  theme: defaultTheme,
  guideDataSources: "https://miro.com/app/board/uXjVO7VckHk=/",
  guideApplications: "https://miro.com/app/board/uXjVO7Utac8=/",
  apiTokenValidityTime: 60 * 60 * 1000, // ms

  developerResources: {
    title: "Developer resources",
    description: "Resources on how to build, integrate and innovate on Dataspaces.",
    sections: [
      {
        title: "Guides",
        description:
          "Tutorials on how to build on and integrate using our Dataspace technology.",
        icon: "basic-guides",
        links: [
          {
            label: "How to create a group",
            href: "https://docs.ioxio.dev/guides/managing-groups/",
          },
          {
            label: "Verify consent in a data source",
            href: "https://docs.ioxio.dev/guides/verifying-consent-in-data-source/",
          },
          {
            label: "How to create data definitions",
            href: "https://docs.ioxio.dev/guides/building-a-data-definition/",
          },
          {
            label: "How to build a data source",
            href: "https://docs.ioxio.dev/guides/building-a-data-source/",
          },
          {
            label: "Verify id_token in a data source",
            href: "https://docs.ioxio.dev/guides/verifying-id-token/",
          },
        ],
      },
      {
        title: "APIs",
        icon: "api-guides",
        description:
          "We offer API docs in multiple formats to ensure they are readable for anyone, no matter their background.",
        links: [
          {
            label: "Product gateway - Swagger UI",
            href: "https://gateway.sandbox.ioxio-dataspace.com/docs",
          },
          {
            label: "Developer portal - Swagger UI",
            href: "https://developer.sandbox.ioxio-dataspace.com/docs",
          },
          {
            label: "Data definitions viewer",
            href: "https://definitions.sandbox.ioxio-dataspace.com/",
          },
        ],
      },
      {
        title: "Video guides",
        icon: "video-guides",
        description:
          "If you prefer to learn by watching and listening check out our videos below.",
        links: [
          {
            label: "Defining data products",
            href: "https://www.youtube.com/watch?v=yPzN04ICsbw&t=194s",
          },
          {
            label: "Productizing data",
            href: "https://www.youtube.com/watch?v=f-f6P_-8zoQ",
          },
        ],
      },
      {
        title: "Other resources",
        icon: "other-guides",
        description: "Other available resources.",
        links: [
          {
            label: "IOXIO Dataspace GitHub",
            href: "https://github.com/ioxio-dataspace",
          },
          {
            label: "Party configuration schema",
            href: "https://docs.ioxio.dev/schemas/party-configuration/",
          },
          {
            label: "Consent request token schema",
            href: "https://docs.ioxio.dev/schemas/consent-request-token/",
          },
          {
            label: "Consent token schema",
            href: "https://docs.ioxio.dev/schemas/consent-token/",
          },
          {
            label: "Dataspace configuration schema",
            href: "https://docs.ioxio.dev/schemas/dataspace-configuration/",
          },
        ],
      },
    ],
  },
}

if (!baseConfig.disableApps) {
  baseConfig.developerResources.sections[0].links.push(
    {
      label: "How to build an application",
      href: "https://docs.ioxio.dev/guides/building-an-application/",
    },
    {
      label: "Use login portal in your application",
      href: "https://docs.ioxio.dev/guides/using-login-provider/",
    }
  )
}

if (runtimeSettings.CONSENT_PROVIDER_URL) {
  baseConfig.developerResources.sections[1].links.splice(2, 0, {
    label: "Consent portal - Swagger UI",
    href: "https://consent.sandbox.ioxio-dataspace.com/docs",
  })
}

for (let feature in baseConfig.features) {
  const override = isEnabledFromEnv(feature)
  if (override !== undefined) {
    baseConfig.features[feature] = override
  }
}
