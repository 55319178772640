import { Icon } from "@ioxio-priv/dataspace-ui"

import { labels } from "@/constants/labels"
import routes from "@/constants/routes"

export const BreadcrumbsNames = {
  ACCESS_CONTROL_KEYS: labels.meta.accessControlKeys.title,
  APPLICATIONS: labels.meta.myApplications.title,
  GROUPS: labels.meta.myGroups.title,
  EDIT_GROUPS: labels.meta.editGroup.title,
  SOURCES: labels.meta.mySources.title,
  CREATE_SOURCES: labels.meta.createSources.title,
  CREATE_APPLICATION: labels.meta.createApplications.title,
  AVAILABLE_SOURCES: labels.meta.availableSources.title,
  CREATE_GROUPS: labels.meta.createGroup.title,
  EDIT_SOURCES: labels.meta.editSources.title,
  LOGIN: labels.meta.login.title,
  TERMS_OF_SERVICE: labels.meta.terms.title,
  RESOURCES: labels.meta.resources.title,
}

export const BreadcrumbsPath = {
  DEVELOPER_PORTAL: { children: <Icon name={"home"} />, href: routes.HOME },
  AVAILABLE_SOURCES: {
    label: BreadcrumbsNames.AVAILABLE_SOURCES,
    href: routes.SOURCES_AVAILABLE,
  },
  APPLICATIONS: { label: BreadcrumbsNames.APPLICATIONS, href: routes.APPLICATIONS },
  GROUPS: { label: BreadcrumbsNames.GROUPS, href: routes.GROUP_LIST },
  EDIT_GROUPS: { label: BreadcrumbsNames.EDIT_GROUPS, href: routes.GROUP_EDIT_EDIT },
  SOURCES: { label: BreadcrumbsNames.SOURCES, href: routes.MY_SOURCES },
  CREATE_SOURCES: {
    label: BreadcrumbsNames.CREATE_SOURCES,
    href: routes.SOURCE_CREATE,
  },
  RESOURCES: { label: BreadcrumbsNames.RESOURCES, href: routes.RESOURCES },
  CREATE_GROUPS: { label: BreadcrumbsNames.CREATE_GROUPS, href: routes.GROUP_CREATE },
  EDIT_SOURCES: { label: BreadcrumbsNames.EDIT_SOURCES, href: routes.SOURCE_EDIT },
  LOGIN: { label: BreadcrumbsNames.LOGIN, href: routes.LOGIN },
  ACCESS_CONTROL_KEYS: {
    label: BreadcrumbsNames.ACCESS_CONTROL_KEYS,
    href: routes.ACCESS_CONTROL_KEYS,
  },
}
