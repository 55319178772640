import React, { useState } from "react"
import { useAsync } from "react-async-hook"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"

import { styled } from "@ioxio-priv/dataspace-ui"

import { HorizontalLine } from "@/commonStyles"
import EditGroupForm from "@/components/EditGroupForm"
import GroupMembers from "@/components/GroupMembers"
import RemoveFormModal from "@/components/RemoveForm"
import { BreadcrumbsNames } from "@/constants/breadcrumbs"
import { labels } from "@/constants/labels"
import routes from "@/constants/routes.json"
import GroupsWrapper from "@/containers/GroupsWrapper"
import InitialLoading from "@/containers/InitialLoading"
import { AuthContext } from "@/context/AuthContext"
import useLayoutOpts from "@/hooks/useLayoutOpts"
import DataSourceGroupsAPI from "@/services/dataSourceGroupsAPI"

export default function GroupsEdit({ history }) {
  const authContext = React.useContext(AuthContext)
  const { GROUP_LIST } = routes
  const [isRemoveFormShown, setRemoveFormShown] = useState(false)
  const [groupResp, setGroupResp] = useState({})
  const [groupMembers, setGroupMembers] = useState([])
  const [isCurrentUserAnOwner, setIsCurrentUserAnOwner] = useState(false)

  const urlParams = useParams()
  const name = urlParams.name

  if (!name) {
    history.push(GROUP_LIST)
  }

  async function _reloadGroupInvitations() {
    const { ok, data, error } = await DataSourceGroupsAPI.getGroupInvitations(name)
    if (ok) {
      _setGroupInvitations(data.invites)
    } else {
      toast.error(error)
    }
  }

  function _setGroupInvitations(invites) {
    const invitations = invites.map((invite) => {
      return {
        email: invite.email,
        sub: invite.inviteId, // use inviteId as sub to cancel invitation
        role: "pending", // role is set as pending for invitations
        originalRole: invite.role,
      }
    })
    const members = groupMembers.filter((item) => item.role !== "pending")
    setGroupMembers(invitations.concat(members))
  }

  function _setGroupMembers(members) {
    const invitations = groupMembers.filter((item) => item.role === "pending")
    setGroupMembers(invitations.concat(members))
  }
  const renderGroupMembershipSection = Boolean(groupMembers.length)

  const appReq = useAsync(async () => {
    const { ok, data } = await DataSourceGroupsAPI.getGroup(name)
    if (ok) {
      setGroupResp(data)
      getGroupMembers(name)
    } else {
      history.push({
        pathname: GROUP_LIST,
        state: { error: "Group not found. Please try again." },
      })
    }
  }, [name])

  //render navbar and footer
  useLayoutOpts(true, true)

  async function getGroupMembers(group) {
    const promises = [
      DataSourceGroupsAPI.getGroupMembers(group),
      DataSourceGroupsAPI.getGroupInvitations(group),
    ]
    // This fails if one of the requests fails
    const [membersResp, invitesResp] = await Promise.all(promises)
    if (membersResp.ok && invitesResp.ok) {
      const members = membersResp.data.members
      const invitations = invitesResp.data.invites.map((invite) => {
        return {
          email: invite.email,
          sub: invite.inviteId, // use inviteId as sub to cancel invitation
          role: "pending", // role is set as pending for invitations
          originalRole: invite.role,
        }
      })
      setGroupMembers(invitations.concat(members))
      const currentUser = members.find((item) => item.sub === authContext.user.sub)
      const currentUserIsAnOwner = currentUser.role === "owner"
      setIsCurrentUserAnOwner(currentUserIsAnOwner)
    } else {
      toast.error(membersResp.error || invitesResp.error)
    }
  }

  async function editGroup(payload, handleErrors) {
    const { ok, data, error } = await DataSourceGroupsAPI.updateGroup(payload)
    if (ok) {
      await appReq.execute()
      toast.success(`Successfully updated group ${data.group}`)
    } else {
      handleErrors(error)
    }
    return { ok }
  }

  async function onDelete() {
    const { ok, error } = await DataSourceGroupsAPI.deleteGroup(groupResp.group)
    if (ok) {
      history.push({
        pathname: GROUP_LIST,
        state: { success: `Successfully deleted group ${groupResp.group}` },
      })
    } else {
      toast.error(error)
    }
    return { ok }
  }

  return appReq.loading ? (
    <InitialLoading />
  ) : (
    <GroupsWrapper
      breadCrumbs={BreadcrumbsNames.EDIT_GROUPS}
      meta={labels.meta.editGroup}
      title={<span>Edit group {groupResp.group}</span>}
    >
      <EditGroupForm
        group={groupResp.group}
        isRemoveFormShown={isRemoveFormShown}
        setRemoveFormShown={setRemoveFormShown}
        initialFormValues={{
          partyConfigurationDomain: groupResp.partyConfigurationDomain || "",
          group: groupResp.group,
          gdprContact: groupResp.gdprContact || "",
        }}
        asyncOnSubmit={editGroup}
        isOwner={isCurrentUserAnOwner}
      />
      {renderGroupMembershipSection && (
        <>
          <CustomHorizontalLine />
          <GroupMembers
            groupMembers={groupMembers}
            _setGroupMembers={_setGroupMembers}
            _setGroupInvitations={_setGroupInvitations}
            _reloadGroupInvitations={_reloadGroupInvitations}
            group={name}
            history={history}
          />
        </>
      )}
      <RemoveFormModal
        isOpen={isRemoveFormShown}
        setIsOpen={setRemoveFormShown}
        name={groupResp.group}
        type="group"
        asyncOnSubmit={onDelete}
        title={`Delete group ${groupResp.group}?`}
      />
    </GroupsWrapper>
  )
}

const CustomHorizontalLine = styled(HorizontalLine)`
  margin: 1.5rem 0;
`
