import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"

import { InfoCard, LinkButton } from "@ioxio-priv/dataspace-ui"
import { styled } from "@mui/material"

import MyDataSourceCard from "@/components/MyDataSourceCard"
import { labels } from "@/constants/labels"
import ROUTES from "@/constants/routes"
import InitialLoading from "@/containers/InitialLoading"
import SourcesWrapper from "@/containers/SourcesWrapper"
import { Icons } from "@/dsIcon"
import useLayoutOpts from "@/hooks/useLayoutOpts"
import useToast from "@/hooks/useToast"
import DataSourceAPI from "@/services/dataSourceAPI"

function DataSourceListing({ listing }) {
  return (
    <ListingWrapper>
      {listing.map((dataSource) => (
        <MyDataSourceCard
          key={dataSource.source + dataSource.definition}
          dataSource={dataSource}
        />
      ))}
      {listing.length === 0 && <InfoCard>You have no data sources</InfoCard>}
    </ListingWrapper>
  )
}

export default function MySources({ location, history }) {
  const { LOGIN, SOURCE_CREATE } = ROUTES
  const [sources, setSources] = useState([])
  const [loading, setLoading] = useState(true)

  // render navbar and footer
  useLayoutOpts(true, true)

  // fetch data sources
  useEffect(() => {
    ;(async () => {
      const { ok, data, error, status } = await DataSourceAPI.getMySources()
      if (ok) {
        setSources(data.dataSources)
        setLoading(false)
      } else {
        if (status === 403) {
          history.push({
            pathname: LOGIN,
            state: { error: error },
          })
        }
        toast.error(error)
      }
    })()
  }, [history])

  // Initialize toasts and render any in the state
  useToast(location.state)

  //sorting data sources
  sources.sort((a, b) => {
    const [aBaseDef, aVersion] = a.definition.split("_v")
    const [bBaseDef, bVersion] = b.definition.split("_v")
    if (aBaseDef !== bBaseDef) {
      // different definition
      return aBaseDef.localeCompare(bBaseDef)
    } else {
      if (aVersion !== bVersion) {
        // same definitions with different versions, sorting DESC
        return aVersion.localeCompare(bVersion) * -1
      } else {
        // same definitions with the same version
        return a.source.localeCompare(b.source)
      }
    }
  })

  return (
    <SourcesWrapper
      meta={labels.meta.mySources}
      title={labels.meta.mySources.title}
      belowHeader={
        <div>
          <AddSourceButton
            baseProps={{ "data-testid": "add-sources" }}
            icon={Icons.add}
            href={SOURCE_CREATE}
            color={"success"}
            variant={"iconText"}
          >
            Add a data source
          </AddSourceButton>
        </div>
      }
    >
      <Divider />
      {loading ? <InitialLoading /> : <DataSourceListing listing={sources} />}
    </SourcesWrapper>
  )
}

const ListingWrapper = styled("div")`
  margin-top: ${(p) => p.theme.spacing(1.5)};
`

const AddSourceButton = styled(LinkButton)`
  margin-bottom: ${(p) => p.theme.spacing(1.5)};
`

const Divider = styled("hr")`
  border-top: 0;
`
