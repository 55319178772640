import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"

import { Breadcrumbs, LinkButton, styled } from "@ioxio-priv/dataspace-ui"
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material"

import { HorizontalLine } from "@/commonStyles"
import AvailableDataSource from "@/components/AvailableDataSource"
import Header from "@/components/Header"
import ResourcesBox from "@/components/ResourcesBox"
import { BreadcrumbsPath } from "@/constants/breadcrumbs"
import routes from "@/constants/routes.json"
import InitialLoading from "@/containers/InitialLoading"
import { useSearchUrl } from "@/context/SourcesSearchContext"
import { Icons } from "@/dsIcon"
import DataSourceAPI from "@/services/dataSourceAPI"
import { sortBySources } from "@/utilities"
import { githubOpenAPISpecUrl, swaggerUIUrl } from "@/utilities/urls"

function DataSourceSearchResults() {
  const [sortedVersionedSources, setsortedVersionedSources] = useState()
  const [productHeader, setProductHeader] = useState(null)
  const [definitions, setDefinitions] = useState([])
  const [switches, setSwitches] = useState({})
  const { definition } = useParams()
  const theme = useTheme()
  const tabletScreen = useMediaQuery(theme.breakpoints.down("lg"))
  const { searchUrl } = useSearchUrl()

  useEffect(() => {
    ;(async () => {
      const { ok, data, error } = await DataSourceAPI.getAvailableDataSources(
        definition
      )
      if (ok) {
        const versionedSources = data.dataSources.map((source) => {
          return {
            ...source,
            baseDefinition: source.definition.split("_v")[0],
            version: source.definition.split("_v")[1],
          }
        })
        versionedSources.sort((a, b) => {
          return parseFloat(b.version) - parseFloat(a.version)
        })

        const versionedAndSortedSources = {}

        for (const obj of versionedSources) {
          const { version } = obj
          if (!versionedAndSortedSources[version]) {
            versionedAndSortedSources[version] = []
          }
          versionedAndSortedSources[version].push(obj)
        }

        for (let key in versionedAndSortedSources) {
          sortBySources(versionedAndSortedSources[key])
        }

        setsortedVersionedSources(versionedAndSortedSources)
      } else {
        toast.error(error)
      }

      const definitionResponse = await DataSourceAPI.getDataSourceDefinitions()
      if (definitionResponse.ok) {
        setDefinitions(definitionResponse.data.definitions)
      } else {
        setProductHeader(undefined)
        toast.error(error)
      }
    })()
  }, [])

  useEffect(() => {
    sortedVersionedSources && setSwitches(updateSwitches())
  }, [sortedVersionedSources])

  useEffect(() => {
    sortedVersionedSources && definitions && getProductHeader()
  }, [definitions])

  function updateSwitches() {
    let switches = {}
    for (let key in sortedVersionedSources) {
      sortedVersionedSources[key].forEach(
        (item, index) => (switches[`${index}-v${item.version}`] = false)
      )
    }
    return switches
  }

  function onSwitch(index) {
    const copyOfSwitches = { ...switches }
    copyOfSwitches[index] = !copyOfSwitches[index]
    setSwitches(copyOfSwitches)
  }

  function findDefinition(baseDefinition, version) {
    return definitions.find(
      (definition) =>
        definition.version === version && definition.definition === baseDefinition
    )
  }

  function getProductData(key) {
    const dataSource = sortedVersionedSources[key][0]
    return findDefinition(dataSource.baseDefinition, dataSource.version)
  }

  function getProductHeader() {
    const versions = Object.keys(sortedVersionedSources)
    for (let version of versions) {
      const dataSource = sortedVersionedSources[version][0]
      const summary = findDefinition(
        dataSource.baseDefinition,
        dataSource.version
      )?.summary
      if (summary) {
        return setProductHeader(summary)
      }
    }
    setProductHeader(undefined)
  }

  function getAdditionalResources(definition) {
    return [
      {
        text: "OpenAPI Spec source",
        link: githubOpenAPISpecUrl(definition),
      },
      // TODO: uncomment/edit when Definition Viewer 2.0 is released
      // {
      //   text: "Open in Definitions Viewer",
      //   link: definitionsViewerUrl(definition),
      // },
      {
        text: "View in Swagger UI",
        link: swaggerUIUrl(definition),
      },
    ]
  }
  return (
    <>
      {sortedVersionedSources && productHeader !== null ? (
        <>
          <Header
            breadCrumb={
              <Breadcrumbs
                current={productHeader}
                paths={[
                  BreadcrumbsPath.DEVELOPER_PORTAL,
                  BreadcrumbsPath.AVAILABLE_SOURCES,
                ]}
              />
            }
            title={productHeader || "Oops! Data source definition not found"}
          >
            <LinkButton
              href={searchUrl ? searchUrl : routes.SOURCES_AVAILABLE}
              variant="outlined"
              icon={Icons.arrowReturn}
              color="secondary"
            >
              Back to search
            </LinkButton>
          </Header>
          {Object.keys(sortedVersionedSources).map(
            (key) =>
              getProductData(key) && (
                <div key={key}>
                  <CustomHorizontalLine />
                  <ProductDataSummary variant="h3">
                    {getProductData(key)?.summary} {`v${key}`}
                  </ProductDataSummary>
                  <Grid container item columnSpacing={"1.5rem"}>
                    <DataGrid item sm={12} lg={8}>
                      <Typography variant="subtitle">
                        {getProductData(key)?.description}
                      </Typography>
                      {tabletScreen && (
                        <>
                          <ResourcesHorizontalLine />
                          <ResourcesBox
                            title={`Additional resources for v${key}`}
                            links={getAdditionalResources(
                              sortedVersionedSources[key][0].definition
                            )}
                            collapsible={true}
                          />
                        </>
                      )}
                      {sortedVersionedSources[key].map((item, index) => (
                        <AvailableDataSource
                          isOpen={switches[`${index}-v${item.version}`]}
                          index={`${index}-v${item.version}`}
                          key={`${item.source}-v${index}`}
                          dataSource={item}
                          updateSwitch={(index) => onSwitch(index)}
                        />
                      ))}
                    </DataGrid>
                    {!tabletScreen && (
                      <Grid item sm={12} lg={4}>
                        <ResourcesBox
                          title={`Additional resources for v${key}`}
                          links={getAdditionalResources(
                            sortedVersionedSources[key][0].definition
                          )}
                        />
                      </Grid>
                    )}
                  </Grid>
                </div>
              )
          )}
        </>
      ) : (
        <InitialLoading />
      )}
    </>
  )
}

export default DataSourceSearchResults

const CustomHorizontalLine = styled(HorizontalLine)`
  margin: 1.5rem 0;
`

const ProductDataSummary = styled(Typography)`
  margin-bottom: 0.5rem;
  min-width: 100%;
`

const ResourcesHorizontalLine = styled(HorizontalLine)`
  margin: 1rem 0 0.5rem;
`

const DataGrid = styled(Grid)`
  width: 100%;
`
