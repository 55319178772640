import React from "react"

import { Tooltip } from "@ioxio-priv/dataspace-ui"
import { styled } from "@mui/material/styles"
import Typography from "@mui/material/Typography"

import FormRadioGroup from "@/components/FormRadioGroup"
import { config } from "@/settings"

const NONE = `
    The <b>X-API-Key</b> header will be optional for your data source
    and its contents will not be verified by the Dataspace.
`

const SELF_MANAGED = `
    The <b>X-API-Key</b> header will be required to be present for your data source
    but its contents will not be verified by the Dataspace.
`

const DATASPACE_VERIFIED = `
    The <b>X-API-Key</b> header will be required and verified by the Dataspace. You can manage which groups are
    allowed to generate API tokens for your source. We recommend you additionally <a target="_blank" rel="noreferrer" href=${config.verifyingAPITokensGuide}> verify the API tokens in your data source</a>.
`
const options = [
  {
    label: "Dataspace verified API tokens",
    description: DATASPACE_VERIFIED,
    value: "dataspace_verified",
  },
]

if (!config.requireVerifiedApiTokens) {
  options.unshift(
    {
      label: "No access control",
      description: NONE,
      value: "none",
    },
    {
      label: "Self-managed API keys",
      description: SELF_MANAGED,
      value: "self_managed",
    }
  )
}

export default function AccessControlMode({ form }) {
  return (
    <div>
      <Header>
        <span>Access control mode</span>
        <Tooltip>
          Choose how the X-API-Key header is handled for your data source.
        </Tooltip>
      </Header>
      <FormRadioGroup
        form={form}
        name="accessControlMode"
        baseProps={{
          "data-testid": "access-control-mode",
        }}
        options={options}
      />
    </div>
  )
}

const Header = styled(Typography)`
  font-weight: 500;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`
