import React, { useEffect, useState } from "react"

import {
  Breadcrumbs,
  Checkbox,
  Icon,
  IconButton,
  InputField,
  styled,
} from "@ioxio-priv/dataspace-ui"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

import { MainContentBox } from "@/commonStyles"
import DefinitionCard from "@/components/DefinitionCard"
import Header from "@/components/Header"
import { InlineLink } from "@/components/InlineLink"
import { MetaData } from "@/components/MetaData"
import ResourcesBox from "@/components/ResourcesBox"
import Spinner from "@/components/Spinner"
import Text from "@/components/Text"
import { BreadcrumbsNames, BreadcrumbsPath } from "@/constants/breadcrumbs"
import { labels } from "@/constants/labels"
import routes from "@/constants/routes"
import { useSearchUrl } from "@/context/SourcesSearchContext"
import { Icons } from "@/dsIcon"
import useLayoutOpts from "@/hooks/useLayoutOpts"
import useSearchDefinitions from "@/hooks/useSearchDefinitions"
import definitionsAPI from "@/services/definitionsAPI"
import { config, dsConfig } from "@/settings"
import { convertToLowerCase } from "@/utilities"

function Tag({ value, OnSelect, selected }) {
  return (
    <TagBody
      data-testid={"tag"}
      name={value}
      onClick={() => OnSelect(value)}
      selected={selected}
    >
      {value}
    </TagBody>
  )
}

function searchTermInDefinition(term, definition) {
  for (const key in definition) {
    if (key === "version" || key === "deprecated") {
      continue
    }
    if (convertToLowerCase(definition[key]).includes(term.toLowerCase())) {
      return true
    }
  }
  return false
}

function searchDefinitions(definitions, search) {
  if (!search) {
    return definitions
  }
  const searchTerms = search.split(" ")

  if (!searchTerms.length) {
    return definitions
  }

  return searchTerms.reduce((acc, curr) => {
    return acc.filter((definition) => searchTermInDefinition(curr, definition))
  }, definitions)
}

export default function SourcesAvailable({ history }) {
  const { searchUrl, setSearchUrl } = useSearchUrl()

  const queryParams = new URLSearchParams(searchUrl)
  const querySearchValue = queryParams.get("search")

  const [showAllTags, setShowAllTags] = useState(false)
  const [tags, setTags] = useState([])
  const [isLoadingTags, setIsLoadingTags] = useState(true)
  const [searchValue, setSearchValue] = useState(querySearchValue || "")
  const {
    definitions,
    loading,
    setSelectedTags,
    selectedTags,
    setStableVersion,
    stableVersion,
  } = useSearchDefinitions()
  // render navbar and footer
  useLayoutOpts(true, true)

  useEffect(() => {
    updateUrl(selectedTags, stableVersion, searchValue)
  }, [selectedTags, stableVersion, searchValue])

  // get tags
  useEffect(async () => {
    const { data, ok } = await definitionsAPI.getTags()
    if (ok) {
      setTags(data.tags)
      setIsLoadingTags(false)
    }
  }, [])

  // fetch available definitions

  const searchResults = searchDefinitions(definitions, searchValue)

  function handleTagSelection(tag) {
    let newTags
    if (selectedTags.includes(tag)) {
      const tags = [...selectedTags]
      newTags = tags.filter((t) => t !== tag)
    } else {
      newTags = [...selectedTags]
      newTags.push(tag)
    }
    setSelectedTags(newTags)
  }

  function updateUrl(tags, version, search) {
    history.replace(
      `/sources/available?tags=${tags}&stableVersion=${version}&search=${search}`
    )
    setSearchUrl(
      `/sources/available?tags=${tags}&stableVersion=${version}&search=${search}`
    )
  }

  return (
    <MainContentBox>
      <MetaData {...labels.meta.availableSources} />
      <Grid container columnSpacing={"3rem"}>
        <Grid container item sm={12} md={12} lg={8}>
          <Header
            breadCrumb={
              <Breadcrumbs
                current={BreadcrumbsNames.AVAILABLE_SOURCES}
                paths={[BreadcrumbsPath.DEVELOPER_PORTAL]}
              />
            }
            title={labels.meta.availableSources.title}
          >
            <Text csx={{ mb: 1 }}>
              Select one of the available definitions to see any published data sources
              for it and get the necessary details to take it into use.
            </Text>
            <Typography>
              To make your own data available here, register a data source on the{" "}
              <InlineLink href={routes.MY_SOURCES}>my data sources</InlineLink> page,
              and publish it.{" "}
              {!config.disableApps && (
                <>
                  Some sources may require authentication, and to get the authentication
                  token you need to register your application on the{" "}
                  <InlineLink href={routes.APPLICATIONS}>my applications</InlineLink>{" "}
                  page, and integrate it to the authentication system.
                </>
              )}
              {config.disableApps && (
                <>
                  Some sources may require authentication, and to get the authentication
                  token you need to integrate your application to the authentication
                  system.
                </>
              )}
            </Typography>
          </Header>
        </Grid>
        <ResourcesGrid item sm={12} md={12} lg={4}>
          {dsConfig.components["Data Definitions"] && (
            <ResourcesBox
              links={[
                {
                  text: " Learn more about the data definitions",
                  link: dsConfig.components["Data Definitions"],
                },
              ]}
            />
          )}
        </ResourcesGrid>
        <Grid item xs={12} md={12} lg={8}>
          <Divider />
          <div>
            <InputField
              placeholder="What kind of data do you want to search?"
              name={"Data source search"}
              value={searchValue}
              handleChange={(e) => {
                setSearchValue(e.target.value)
              }}
              startItem={<SearchIcon name={Icons.search} />}
              endItem={
                <InputEndAdornment>
                  {loading && <Spinner size={20} color="success" thickness={7} />}
                  {searchValue && (
                    <ClearButtonWrapper
                      onClick={() => {
                        setSearchValue("")
                      }}
                      data-testid="clear-icon"
                    >
                      <Icon name={Icons.cancel} />
                    </ClearButtonWrapper>
                  )}
                </InputEndAdornment>
              }
            />
            {!isLoadingTags && tags.length > 0 && (
              <TagsWrapper>
                <Tags hidetags={showAllTags.toString()}>
                  {tags.map((tag, i) => {
                    return (
                      <Tag
                        value={tag}
                        selected={selectedTags && selectedTags.includes(tag)}
                        key={tag + `${i}`}
                        OnSelect={(tag) => handleTagSelection(tag)}
                      />
                    )
                  })}
                </Tags>
                <div>
                  <IconButton
                    baseProps={{ "data-testid": "show-all-tags" }}
                    onClick={() => {
                      setShowAllTags(!showAllTags)
                    }}
                    variant={"outlined"}
                    color="secondary"
                    icon={!showAllTags ? Icons.chevronRight : Icons.arrowDown}
                  />
                </div>
              </TagsWrapper>
            )}

            <CheckboxWrapper>
              <Checkbox
                handleChange={() => setStableVersion(!stableVersion)}
                checked={stableVersion}
                label="Version ≥ 1.0"
              />
            </CheckboxWrapper>

            <AvailableDataSources>
              {searchResults &&
                searchResults.map((definition, i) => {
                  return (
                    <DefinitionCard
                      key={definition.title + `${i}`}
                      title={definition.baseDefinition}
                      subtitle={definition.title}
                      description={definition.description}
                      version={definition.version}
                      tags={definition.tags}
                    />
                  )
                })}
            </AvailableDataSources>
          </div>
        </Grid>
      </Grid>
    </MainContentBox>
  )
}

const SearchIcon = styled(Icon)`
  color: ${(p) => p.theme.palette.primary.dark};
`

const InputEndAdornment = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`

const ClearButtonWrapper = styled(Box)`
  cursor: pointer;
  svg {
    display: block;
    color: ${(p) => p.theme.palette.primary.dark};
  }
`

const Divider = styled("hr")`
  border-top: 0;
  margin-bottom: 1.5rem;
`

const TagsWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
  justify-content: space-between;
`
const Tags = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-wrap: wrap;
  overflow: hidden;
  max-height: ${(p) => (p.hidetags === "false" ? "42px" : "500px")};
  transition: all ${(p) => (p.hidetags === "false" ? "300ms" : "600ms")} ease-out;
`

const TagBody = styled(Typography)`
  border: 1px solid ${(p) => p.theme.palette.secondary.main};
  padding: 0.5rem;
  background-color: ${(p) =>
    p.selected ? p.theme.palette.secondary.main : p.theme.palette.neutral.light};
  border-radius: 5px;
  color: ${(p) => (!p.selected ? "default" : p.theme.palette.neutral.light)};
  cursor: pointer;
  transition: all 300ms ease-out;

  &:hover {
    background-color: ${(p) => p.theme.palette.primary.light};
    border-color: ${(p) => p.theme.palette.primary.light};
    color: ${(p) => p.theme.palette.secondary.main};
    &:active {
      background-color: ${(p) =>
        !p.selected ? p.theme.palette.secondary.main : p.theme.palette.neutral.light};
      color: ${(p) => (p.selected ? "default" : p.theme.palette.neutral.light)};
    }
  }
`

const CheckboxWrapper = styled(Box)`
  margin: 1.5rem 0;
  width: auto;
`

const AvailableDataSources = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const ResourcesGrid = styled(Grid)`
  margin-bottom: 1.5rem;
  width: 100%;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-top: 7.2rem;
  }
`
