import React from "react"

import { Breadcrumbs } from "@ioxio-priv/dataspace-ui"
import Grid from "@mui/material/Grid"

import { MainContentBox } from "@/commonStyles"
import { GroupsHelp } from "@/components/GroupsHelp"
import Header from "@/components/Header"
import { MetaData } from "@/components/MetaData"
import ResourcesBox from "@/components/ResourcesBox"
import { BreadcrumbsPath } from "@/constants/breadcrumbs"
import useLayoutOpts from "@/hooks/useLayoutOpts"
import { config } from "@/settings"

export default function GroupsWrapper({
  children,
  belowHeader,
  breadCrumbs,
  title,
  meta,
  editPage = false,
}) {
  // render navbar and footer
  useLayoutOpts(true, true)

  const resources = (
    <ResourcesBox
      links={[
        {
          text: "Read more about party configuration domain",
          link: config.partyConfigurationDocs,
        },
        {
          text: "Guide for creating and hosting party configuration",
          link: "https://docs.ioxio.dev/guides/managing-groups/#creating-and-hosting-party-configuration",
        },
      ]}
    />
  )

  return (
    <MainContentBox>
      <MetaData {...meta} />
      <Grid container columnSpacing={"1.5rem"}>
        <Grid item xs={12} lg={8}>
          <Header
            breadCrumb={
              <Breadcrumbs
                current={breadCrumbs}
                paths={[BreadcrumbsPath.DEVELOPER_PORTAL, BreadcrumbsPath.GROUPS]}
              />
            }
            title={title}
          >
            {!editPage && <GroupsHelp />}
          </Header>
          {belowHeader}
          <Grid item display={{ lg: "none" }}>
            {resources}
          </Grid>
          {children}
        </Grid>
        <Grid item lg={4} mt="7.2rem" display={{ xs: "none", lg: "block" }}>
          {resources}
        </Grid>
      </Grid>
    </MainContentBox>
  )
}
