import React from "react"
import { toast } from "react-toastify"

import { CreateAppForm, ThirdPartyCreateAppForm } from "@/components/AppForm"
import { BreadcrumbsNames } from "@/constants/breadcrumbs"
import { labels } from "@/constants/labels"
import ApplicationWrapper from "@/containers/ApplicationWrapper"
import useLayoutOpts from "@/hooks/useLayoutOpts"
import useMyGroups from "@/hooks/useMyGroups"
import AppsAPI from "@/services/appsAPI"
import { config } from "@/settings"

import InitialLoading from "./InitialLoading"

export default function CreateApplicationScreen({ history }) {
  // render navbar and footer
  useLayoutOpts(true, true)
  const { myGroups, loading: loadingMyGroups } = useMyGroups()

  async function createApplication(payload, handleErrors) {
    const { ok, error, data } = await AppsAPI.createApplication(payload)
    if (ok) {
      await history.push(`/apps/edit/${data.name}/${data.clientId}`)
      toast.success("Application successfully created")
    } else {
      handleErrors(error)
    }
    return { ok }
  }

  if (loadingMyGroups) {
    return <InitialLoading />
  }

  const AppForm = config.externalAppManagement ? ThirdPartyCreateAppForm : CreateAppForm
  return (
    <ApplicationWrapper
      breadCrumbs={BreadcrumbsNames.CREATE_APPLICATION}
      meta={labels.meta.createApplications}
      title={labels.meta.createApplications.title}
    >
      <AppForm groups={myGroups} asyncOnSubmit={createApplication} />
    </ApplicationWrapper>
  )
}
