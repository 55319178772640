import { getMenuItems } from "@ioxio-priv/dataspace-utils"

import { baseConfig, baseDsConfig } from "./base"

export const menuSections = getMenuItems()

// Dataspace specific configuration
export const dsConfig = {
  ...baseDsConfig,
}

// Application specific configuration
export const config = {
  ...baseConfig,
}
