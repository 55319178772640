import React, { useState } from "react"
import { useForm } from "react-hook-form"

import { joiResolver } from "@hookform/resolvers/joi"
import {
  Button,
  IconLink,
  InfoCard,
  LinkButton,
  Submit,
} from "@ioxio-priv/dataspace-ui"
import { styled, useMediaQuery, useTheme } from "@mui/material"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"

import routes from "@/constants/routes"

import { getFormData, makeSchema } from "./validation"
import { FormBody, FormBox, HorizontalLine } from "../../commonStyles"
import Form from "../../components/Form"
import { labels } from "../../constants/labels"
import { Icons } from "../../dsIcon"
import { toastError } from "../../utilities/errors"
import FormInput from "../FormInput"
import FormSelect from "../FormSelect"

export default function CreateAppForm({
  groups,
  asyncOnSubmit = async () => ({ ok: true }),
}) {
  const [redirectUriIds, setRedirectUriIds] = useState([0])
  const [logoutRedirectUriIds, setLogoutRedirectUriIds] = useState([0])

  const theme = useTheme()
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const schema = makeSchema(redirectUriIds, logoutRedirectUriIds)
  const form = useForm({
    mode: "onSubmit",
    resolver: joiResolver(schema),
  })

  async function onSubmit(evt) {
    evt.preventDefault()
  }

  async function handleBackendErrors(errors) {
    // `errors` is either a string or an array with FastAPI validation errors
    if (!Array.isArray(errors)) {
      return toastError("Failed to create an application", errors)
    }
    for (let error of errors) {
      const { loc, msg } = error
      const [, field, i] = loc || []
      let formFieldName = field
      if (field === "redirectUris") {
        formFieldName = `redirectUri${i}`
      } else if (field === "logoutRedirectUris") {
        formFieldName = `logoutRedirectUri${i}`
      }
      form.setError(formFieldName, { type: "server", message: msg })
    }
  }

  async function _asyncOnSubmit() {
    const isFormValid = await form.trigger()
    if (isFormValid) {
      const data = getFormData(form, redirectUriIds, logoutRedirectUriIds)
      return asyncOnSubmit(data, handleBackendErrors)
    }
    return {
      ok: false,
    }
  }

  function addRedirectUri() {
    const maxId = Math.max.apply(null, redirectUriIds)
    setRedirectUriIds([...redirectUriIds, maxId + 1])
  }

  function removeRedirectUri(idToRemove) {
    setRedirectUriIds(redirectUriIds.filter((id) => id !== idToRemove))
    form.unregister(`redirectUri${idToRemove}`)
  }

  function addLogoutRedirectUri() {
    const maxId = Math.max.apply(null, logoutRedirectUriIds)
    setLogoutRedirectUriIds([...logoutRedirectUriIds, maxId + 1])
  }

  function removeLogoutRedirectUri(idToRemove) {
    setLogoutRedirectUriIds(logoutRedirectUriIds.filter((id) => id !== idToRemove))
    form.unregister(`logoutRedirectUri${idToRemove}`)
  }

  const FormContainer = (
    <FormBox>
      <FormBody>
        <FormInput
          name={"name"}
          label={labels.application.fields.name.label}
          form={form}
          placeholder={"Application name"}
          required={true}
          tooltipText={labels.application.fields.name.tooltipText}
        />
        <FormSelect
          form={form}
          name={"group"}
          label={labels.application.fields.group.label}
          required={true}
          placeholder={"Select Group"}
          showAddGroupLink={true}
          options={groups.map((item) => item.group)}
          baseProps={{ "data-testid": "groups-dropdown" }}
          tooltipText={labels.application.fields.group.tooltipText}
        />
      </FormBody>
      <HorizontalLine />
      <FormBody>
        <RedirectBlockGrid item>
          {redirectUriIds.map((id, index) => (
            <FormInput
              key={id}
              name={`redirectUri${id}`}
              index={index}
              label={labels.application.fields.redirectURIs.label}
              required={true}
              placeholder={"https://"}
              tooltipText={labels.application.fields.redirectURIs.tooltipText}
              form={form}
              onRemoveClick={
                redirectUriIds.length > 1 || index > 0 ? removeRedirectUri : null
              }
              uriId={id}
            />
          ))}
          <Button
            color={"success"}
            variant={"iconText"}
            icon={Icons.add}
            onClick={addRedirectUri}
          >
            Add
          </Button>
        </RedirectBlockGrid>
        <RedirectBlockGrid item>
          {logoutRedirectUriIds.map((id, index) => (
            <FormInput
              key={id}
              index={index}
              name={`logoutRedirectUri${id}`}
              label={labels.application.fields.logoutRedirectURIs.label}
              tooltipText={labels.application.fields.logoutRedirectURIs.tooltipText}
              required={true}
              placeholder={"https://"}
              form={form}
              onRemoveClick={
                logoutRedirectUriIds.length > 1 || index > 0
                  ? removeLogoutRedirectUri
                  : null
              }
              uriId={id}
            />
          ))}
          <Button
            color={"success"}
            variant={"iconText"}
            icon={Icons.add}
            onClick={addLogoutRedirectUri}
          >
            Add
          </Button>
        </RedirectBlockGrid>
      </FormBody>
      <HorizontalLine />
      <FormBody>
        <FormInput
          name={"appUrl"}
          label={labels.application.fields.website.label}
          placeholder={"https://"}
          form={form}
          tooltipText={labels.application.fields.website.tooltipText}
        />
        <FormInput
          name={"privacyPolicy"}
          label={labels.application.fields.privacyPolicy.label}
          placeholder={"https://"}
          tooltipText={labels.application.fields.privacyPolicy.tooltipText}
          form={form}
        />
      </FormBody>
    </FormBox>
  )

  return (
    <Wrapper>
      <Form
        onSubmit={onSubmit}
        rightButtons={
          mobileScreen ? (
            <>
              <IconLink
                icon={Icons.cancel}
                href={routes.APPLICATIONS}
                variant={"outlined"}
              />
              <Submit
                icon={Icons.success}
                label={"Create"}
                iconOnly
                color="success"
                asyncOnClick={{
                  asyncFn: _asyncOnSubmit,
                }}
              />
            </>
          ) : (
            <>
              <LinkButton
                variant={"outlined"}
                icon={Icons.cancel}
                href={routes.APPLICATIONS}
              >
                Cancel
              </LinkButton>
              <Submit
                icon={Icons.success}
                color={"success"}
                asyncOnClick={{ asyncFn: _asyncOnSubmit }}
              >
                Create
              </Submit>
            </>
          )
        }
      >
        {FormContainer}
      </Form>
      <InfoCard>{labels.application.infoBox.createSecret}</InfoCard>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1.5rem;
`

const RedirectBlockGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
`
