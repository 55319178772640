import React, { useState } from "react"
import { useAsync } from "react-async-hook"
import { toast } from "react-toastify"

import { InfoCard, LinkButton, styled } from "@ioxio-priv/dataspace-ui"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"

import ApplicationItem from "@/components/AppItem"
import Spinner from "@/components/Spinner"
import { labels } from "@/constants/labels"
import ROUTES from "@/constants/routes.json"
import ApplicationWrapper from "@/containers/ApplicationWrapper"
import { Icons } from "@/dsIcon"
import useLayoutOpts from "@/hooks/useLayoutOpts"
import AppsAPI from "@/services/appsAPI"

const spinnerSx = {
  m: 4,
}

export default function ApplicationsScreen({ history, location }) {
  // render navbar and footer
  useLayoutOpts(true, true)

  const [apps, setApps] = useState([])

  const appsReq = useAsync(async () => {
    const { ok, data } = await AppsAPI.listApplications()
    if (ok && Object.prototype.hasOwnProperty.call(data, "apps")) {
      setApps(data.apps)
    }
  }, [])

  const noAppsFound = apps.length === 0

  async function removeApplication(clientId) {
    const { ok } = await AppsAPI.removeApplication(clientId)
    if (ok) {
      setApps(apps.filter((app) => app.clientId !== clientId))
      toast.success("Application has been deleted")
    } else {
      toast.error("Failed to remove application. Please contact support")
    }
    return { ok }
  }
  return (
    <ApplicationWrapper
      belowHeader={
        <Box mb={"1.5rem"}>
          <LinkButton
            color={"success"}
            variant={"iconText"}
            icon={Icons.add}
            href={ROUTES.APPLICATION_CREATE}
          >
            {labels.application.buttons.add}
          </LinkButton>
        </Box>
      }
      meta={labels.meta.myApplications}
      title={labels.meta.myApplications.title}
    >
      <HorizontalLine />
      {appsReq.loading && <Spinner csx={spinnerSx} />}
      {!appsReq.loading && !noAppsFound && (
        <AppListGrid container>
          {apps.map((app) => (
            <ApplicationItem
              key={app.clientId}
              name={app.name}
              clientId={app.clientId}
              asyncOnDelete={removeApplication}
            />
          ))}
        </AppListGrid>
      )}
      {!appsReq.loading && noAppsFound && (
        <InfoCard>{labels.application.infoBox.noApp}</InfoCard>
      )}
    </ApplicationWrapper>
  )
}

const HorizontalLine = styled("hr")`
  border-top: 0;
  margin-bottom: 1.5rem;
`

const AppListGrid = styled(Grid)`
  flex-direction: column;
  justify-content: normal;
  align-items: normal;
  gap: 1.5rem;
  margin: 1.5rem 0;
`
