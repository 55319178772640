import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"

import {
  Button,
  IconButton,
  IconLink,
  InputField,
  LinkButton,
  styled,
  Submit,
} from "@ioxio-priv/dataspace-ui"
import { Grid, useMediaQuery, useTheme } from "@mui/material"

import { Icons } from "@/dsIcon"

import { getThirdPartyFormData } from "./validation"
import { FormBody, FormBox } from "../../commonStyles"
import { labels } from "../../constants/labels"
import routes from "../../constants/routes"
import { copyToClipboard } from "../../utilities"
import { toastError } from "../../utilities/errors"
import Form from "../Form"
import RemoveFormModal from "../RemoveForm"

function FormInput({
  name,
  label,
  tooltipText,
  form,
  uriId = undefined,
  onRemoveClick = undefined,
  readonly = false,
  placeholder = undefined,
}) {
  let err = ""
  if (Object.prototype.hasOwnProperty.call(form.formState.errors, name)) {
    err = form.formState.errors[name].message
  }

  return (
    <InputWithControls>
      <Controller
        name={name}
        control={form.control}
        defaultValue={""}
        render={({ field }) => (
          <InputField
            label={label}
            name={name}
            handleChange={field.onChange}
            error={err}
            tooltipText={tooltipText}
            readonly={readonly}
            baseProps={{ ...field }}
            placeholder={placeholder}
          />
        )}
      />
      {!readonly && onRemoveClick && (
        <Button color={"error"} onClick={() => onRemoveClick(uriId)} label={"Delete"} />
      )}
    </InputWithControls>
  )
}

function defaultFormValues(app) {
  return {
    name: app.name,
    appUrl: app.appUrl,
    privacyPolicy: app.privacyPolicy,
    group: app.group,
  }
}

/* --- Component itself --- */

export default function ThirdPartyEditAppForm({
  app = {},
  asyncOnSubmit = async () => ({ ok: true }),
  onAppRemove = async () => ({ ok: true }),
  csx = {},
}) {
  // UI state
  const [isRemoveFormShown, setRemoveFormShown] = useState(false)

  const form = useForm({
    mode: "onSubmit",
    defaultValues: defaultFormValues(app),
  })

  const theme = useTheme()
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"))

  async function onSubmit(evt) {
    evt.preventDefault()
  }

  async function handleBackendErrors(errors) {
    // `errors` is either a string or an array with FastAPI validation errors
    if (!Array.isArray(errors)) {
      return toastError("Failed to update the application", errors)
    }
    for (let error of errors) {
      const { loc, msg } = error
      const [, field] = loc || []
      form.setError(field, { type: "server", message: msg })
    }
  }

  async function _asyncOnSubmit() {
    const isFormValid = await form.trigger()
    if (isFormValid) {
      const data = getThirdPartyFormData(form)
      return asyncOnSubmit(data, handleBackendErrors)
    }
    return {
      ok: false,
    }
  }

  const FormContainer = (
    <FormBox>
      <FormBody>
        <FormInput
          name={"name"}
          label={labels.application.fields.name.label}
          form={form}
          tooltipText={labels.application.fields.name.tooltipText}
        />
        <FormInput
          name={"group"}
          label={labels.application.fields.group.label}
          form={form}
          readonly
          tooltipText={labels.application.fields.group.tooltipText}
        />
      </FormBody>
      <hr />
      <FormBody>
        <FormInput
          name={"appUrl"}
          tooltipText={labels.application.fields.website.tooltipText}
          placeholder={"https://"}
          label={labels.application.fields.website.label}
          form={form}
        />
        <FormInput
          name={"privacyPolicy"}
          tooltipText={labels.application.fields.privacyPolicy.tooltipText}
          placeholder={"https://"}
          label={labels.application.fields.privacyPolicy.label}
          form={form}
        />
      </FormBody>
      <hr />
      <FormBody>
        <InputField
          name={"clientID"}
          label={labels.application.fields.clientID.label}
          value={app.clientId}
          tooltipText={labels.application.fields.clientID.tooltipText}
          readonly
          baseProps={{ readonly: true }}
        >
          <IconButton
            icon={Icons.copy}
            title={"Copy client ID"}
            color={"secondary"}
            variant={"outlined"}
            onClick={() =>
              copyToClipboard(
                app.clientId,
                "Client ID has been copied to the clipboard"
              )
            }
          />
        </InputField>
      </FormBody>

      <RemoveFormModal
        isOpen={isRemoveFormShown}
        setIsOpen={setRemoveFormShown}
        name={app.name}
        type="application"
        asyncOnSubmit={onAppRemove}
        title={`Delete application ${app.name}`}
      />
    </FormBox>
  )

  return (
    <Form
      onSubmit={onSubmit}
      leftButtons={
        mobileScreen ? (
          <IconButton
            color={"error"}
            onClick={() => setRemoveFormShown(true)}
            icon={Icons.delete}
            iconVariant="outlined"
            baseProps={{ "data-testid": "open-delete-dialog" }}
          />
        ) : (
          <Button
            color={"error"}
            icon={Icons.delete}
            iconVariant={"outlined"}
            onClick={() => setRemoveFormShown(true)}
            baseProps={{ "data-testid": "open-delete-dialog" }}
          >
            Delete
          </Button>
        )
      }
      rightButtons={
        mobileScreen ? (
          <>
            <IconLink
              icon={Icons.cancel}
              href={routes.APPLICATIONS}
              variant={"outlined"}
            />
            <Submit
              icon={Icons.success}
              label={"Save"}
              iconOnly
              color="success"
              asyncOnClick={{
                asyncFn: _asyncOnSubmit,
              }}
            />
          </>
        ) : (
          <>
            <LinkButton
              variant={"outlined"}
              icon={Icons.cancel}
              href={routes.APPLICATIONS}
            >
              Cancel
            </LinkButton>
            <Submit
              icon={Icons.success}
              color={"success"}
              asyncOnClick={{ asyncFn: _asyncOnSubmit }}
            >
              Save
            </Submit>
          </>
        )
      }
    >
      {FormContainer}
    </Form>
  )
}

const InputWithControls = styled(Grid)`
  margin-bottom: 0.5rem;
`
