import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"

import { Breadcrumbs, styled } from "@ioxio-priv/dataspace-ui"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

import { MainContentBox } from "@/commonStyles"
import { GroupsWithAccessToDS } from "@/components/GroupsWithAccessToDS"
import Header from "@/components/Header"
import { MetaData } from "@/components/MetaData"
import Spinner from "@/components/Spinner"
import { BreadcrumbsPath } from "@/constants/breadcrumbs"
import { labels } from "@/constants/labels"
import useGetDefinitions from "@/hooks/useGetDefinitions"
import useLayoutOpts from "@/hooks/useLayoutOpts"
import ApiTokenAPI from "@/services/apiTokenAPI"

export default function AccessControlKeys({ location }) {
  // render navbar and footer
  useLayoutOpts(true, true)

  const [sources, setSources] = useState([])
  const [loadingSources, setLoadingSources] = useState(true)

  const { definitions, loading: loadingDefinitions } = useGetDefinitions()

  useEffect(() => {
    ;(async () => {
      setLoadingSources(true)
      const { ok, data, error } = await ApiTokenAPI.listAvailableSources()
      if (ok) {
        setSources(data.results)
      } else {
        toast.error(error)
        setSources([])
      }
      setLoadingSources(false)
    })()
  }, [])

  return (
    <MainContentBox>
      <MetaData {...labels.meta.accessControlKeys} />
      <Grid container columnSpacing={"1.5rem"}>
        <Grid item xs={12} lg={8}>
          <Header
            breadCrumb={
              <Breadcrumbs
                paths={[
                  BreadcrumbsPath.DEVELOPER_PORTAL,
                  BreadcrumbsPath.ACCESS_CONTROL_KEYS,
                ]}
              />
            }
            title={labels.meta.accessControlKeys.title}
          >
            <p>
              Manage your API access control keys for data sources that require
              authentication via Dataspace managed API tokens. These access control keys
              can be used to generate API tokens for your applications.
            </p>
          </Header>
          <Content>
            <Typography variant="h2">
              Your groups with access to data sources
            </Typography>
            {(loadingSources || loadingDefinitions) && (
              <SpinnerWrapper>
                <Spinner />
              </SpinnerWrapper>
            )}
            {!loadingSources && !loadingDefinitions && (
              <GroupsWithAccessToDS sources={sources} definitions={definitions} />
            )}
          </Content>
        </Grid>
      </Grid>
    </MainContentBox>
  )
}

const SpinnerWrapper = styled("div")`
  margin-top: 2rem;
`

const Content = styled("div")`
  margin-top: 2.25rem;
`
