import React from "react"
import { useHistory } from "react-router-dom"

import CreateDataSourceForm from "@/components/CreateDataSourceForm"
import { BreadcrumbsNames } from "@/constants/breadcrumbs"
import { labels } from "@/constants/labels"
import SourcesWrapper from "@/containers/SourcesWrapper"
import useLayoutOpts from "@/hooks/useLayoutOpts"
import DataSourceAPI from "@/services/dataSourceAPI"

export default function SourcesCreate() {
  // render navbar and footer
  useLayoutOpts(true, true)

  const history = useHistory()

  async function createDataSource(payload, handleErrors) {
    const { ok, data, error } = await DataSourceAPI.create(payload)
    if (ok) {
      history.push({
        pathname: `/sources/edit/${data.definition}/${data.source}`,
        state: {
          success: `Successfully created data source ${data.source}`,
        },
      })
    } else {
      handleErrors(error)
    }
    return { ok }
  }

  return (
    <SourcesWrapper
      title={labels.meta.createSources.title}
      meta={labels.meta.createSources}
      breadCrumbs={BreadcrumbsNames.CREATE_SOURCES}
    >
      <CreateDataSourceForm asyncOnSubmit={createDataSource} />
    </SourcesWrapper>
  )
}
