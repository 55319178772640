import React, { useState } from "react"
import { useForm } from "react-hook-form"

import { joiResolver } from "@hookform/resolvers/joi"
import {
  Button,
  IconButton,
  IconLink,
  InputField,
  LinkButton,
  Submit,
} from "@ioxio-priv/dataspace-ui"
import { Link, useMediaQuery } from "@mui/material"
import { styled, useTheme } from "@mui/material/styles"

import { FormBody, HorizontalLine } from "@/commonStyles"
import AccessControlMode from "@/components/AccessControlMode"
import { SCHEMA } from "@/components/EditDataSourceForm/validation"
import Form from "@/components/Form"
import FormInput from "@/components/FormInput"
import FormRadioGroup from "@/components/FormRadioGroup"
import { labels } from "@/constants/labels"
import routes from "@/constants/routes"
import { Icons } from "@/dsIcon"
import DataSourceAPI from "@/services/dataSourceAPI"
import { config } from "@/settings"
import { copyToClipboard, dataSourceListingOptions } from "@/utilities"
import { toastError } from "@/utilities/errors"

export default function EditDataSourceForm({
  definition,
  source,
  initialFormValues,
  setRemoveFormShown,
  asyncOnSubmit = async () => ({ ok: true }),
}) {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const form = useForm({
    mode: "onSubmit",
    resolver: joiResolver(SCHEMA),
    defaultValues: {
      baseUrl: initialFormValues.baseUrl,
      state: initialFormValues.state,
      accessControlMode: initialFormValues.accessControlMode,
    },
  })

  const [previewToken, setPreviewToken] = useState(initialFormValues.previewToken)

  async function onSubmit(evt) {
    evt.preventDefault()
  }

  async function handleBackendErrors(errors) {
    // `errors` is either a string or an array with FastAPI validation errors
    if (!Array.isArray(errors)) {
      return toastError("Failed to edit the application", errors)
    }
    for (let error of errors) {
      const { loc, msg } = error
      const [, field] = loc || []
      form.setError(field, { type: "server", message: msg })
    }
  }

  async function _asyncOnSubmit() {
    const isFormValid = await form.trigger()
    if (!isFormValid) {
      return {
        ok: false,
      }
    }

    const data = form.getValues()
    const payload = {
      source,
      definition,
      sourceUrl: data.baseUrl,
      state: data.state,
      accessControlMode: data.accessControlMode,
    }
    return asyncOnSubmit(payload, handleBackendErrors)
  }

  async function refreshToken() {
    const { ok, data } = await DataSourceAPI.refreshPreviewToken({
      source,
      definition,
    })
    if (ok) {
      setPreviewToken(data.previewToken)
    }
    return { ok }
  }

  return (
    <Form
      leftButtons={
        <>
          {smallScreen ? (
            <IconButton
              baseProps={{
                "data-testid": "delete",
              }}
              icon={Icons.delete}
              iconVariant={"outlined"}
              color={"error"}
              onClick={() => setRemoveFormShown(true)}
            />
          ) : (
            <Button
              baseProps={{
                "data-testid": "delete",
              }}
              icon={Icons.delete}
              iconVariant={"outlined"}
              color={"error"}
              onClick={() => setRemoveFormShown(true)}
            >
              Delete
            </Button>
          )}
        </>
      }
      onSubmit={onSubmit}
      rightButtons={
        <>
          {smallScreen ? (
            <>
              <IconLink
                href={routes.MY_SOURCES}
                variant={"outlined"}
                icon={Icons.cancel}
              />
              <Submit
                color="success"
                icon={Icons.success}
                iconOnly
                label={"Save"}
                asyncOnClick={{
                  asyncFn: _asyncOnSubmit,
                }}
              />
            </>
          ) : (
            <>
              <LinkButton
                variant={"outlined"}
                icon={Icons.cancel}
                href={routes.MY_SOURCES}
              >
                Cancel
              </LinkButton>
              <Submit
                color="success"
                icon={Icons.success}
                asyncOnClick={{
                  asyncFn: _asyncOnSubmit,
                }}
              >
                Save
              </Submit>
            </>
          )}
        </>
      }
    >
      <FormBody>
        <InputField
          baseProps={{
            tabIndex: -1,
            "data-testid": "definition",
          }}
          readonly={true}
          required={true}
          value={definition}
          name={"definition"}
          id={"definition"}
          label={labels.dataSource.fields.dataProduct.label}
          placeholder={"Data product"}
          tooltipText={labels.dataSource.fields.dataProduct.tooltipText}
          description={
            <>
              Which data product is your source implementing? View definitions or
              propose new ones at{" "}
              <Link target="_blank" rel="noreferrer" href={config.sourcesRepo}>
                {config.sourcesRepo}
              </Link>
            </>
          }
        />
        <InputField
          baseProps={{
            tabIndex: -1,
            "data-testid": "source",
          }}
          value={source}
          label={labels.dataSource.fields.source.label}
          name={"source"}
          required={true}
          readonly={true}
          tooltipText={labels.dataSource.fields.source.tooltipText}
          placeholder={"Source"}
          description={
            <>
              The data product is published at{" "}
              <Highlight>
                {`${config.productGateway}/${definition}?source=${source}`}
              </Highlight>
            </>
          }
        />
      </FormBody>
      <HorizontalLine />
      <FormBody>
        <FormInput
          baseProps={{
            autoCapitalize: "none",
            "data-testid": "baseUrl",
          }}
          required={true}
          label={labels.dataSource.fields.baseUrl.label}
          name={"baseUrl"}
          form={form}
          placeholder={"https://"}
          tooltipText={labels.dataSource.fields.baseUrl.tooltipText}
          description={
            <>
              The base URL for your productized data source. Must use HTTPS. Requests
              will be sent to{" "}
              <Highlight>
                {initialFormValues.baseUrl}/{definition}
              </Highlight>
            </>
          }
        />
        <InputField
          value={previewToken}
          id={"previewToken"}
          label={labels.dataSource.fields.previewToken.label}
          name={"previewToken"}
          readonly
          tooltipText={labels.dataSource.fields.previewToken.tooltipText}
        >
          <IconButton
            icon={Icons.copy}
            title={"Copy"}
            color={"secondary"}
            variant={"outlined"}
            onClick={() =>
              copyToClipboard(
                previewToken,
                "Preview token has been copied to the clipboard"
              )
            }
          />
          <IconButton
            icon={Icons.cached}
            title={"Refresh"}
            color={"secondary"}
            variant={"outlined"}
            onClick={() => refreshToken()}
          />
        </InputField>
        <FormRadioGroup
          form={form}
          name="state"
          baseProps={{
            "data-testid": "state",
          }}
          options={dataSourceListingOptions(source)}
        />
        <AccessControlMode form={form} />
      </FormBody>
    </Form>
  )
}

const Highlight = styled("span")`
  font-style: italic;
`
